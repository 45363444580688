import React from 'react';
import { Link, Outlet } from 'react-router-dom';

function BaseLayout() {
  return (
    <div className="min-h-screen bg-white flex flex-col justify-center font-poppins">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto w-full">
        <div className="relative bg-white">
          <div className='px-8 pt-2'>
            <h1 className="text-2xl mb-2 cursor-pointer text-dark-blue font-poppins">
              <Link to="/">MYAN</Link>
            </h1>
            <div className="w-full mb-4" style={{ height: '1px', backgroundColor: '#D9D9D9' }} />
          </div>
          <main className="flex-grow container mx-auto px-4 py-8">
            <Outlet />
          </main>
        </div>
        <footer className="bg-dark-blue text-white py-4">
          <div className="container mx-auto px-4 text-center">
            <button
              onClick={() => window.open('mailto:maria@myan.uk?subject=Request%20a%20Feature&body=Please%20describe%20the%20feature%20you%20would%20like%20to%20request:', '_blank')}
              className="bg-dark-blue border border-white text-white py-2 px-4 w-4/5 max-w-md rounded-full text-sm hover:bg my-4"
            >
              REQUEST A FEATURE
            </button>
            <div className="my-2 space-y-2"> 
              Created by{' '}
              <a href="https://www.linkedin.com/in/marialuqueanguita/" className="underline hover:text-blue-600" target="_blank" rel="noopener noreferrer">Maria</a>,{' '}
              <a href="https://www.linkedin.com/in/nima-barzin/" className="underline hover:text-blue-600" target="_blank" rel="noopener noreferrer">Nima</a>,{' '}
              <a href="https://www.linkedin.com/in/roc%C3%ADo-camacho-perez-de-sevilla/" className="underline hover:text-blue-600" target="_blank" rel="noopener noreferrer">Rocio</a> and{' '}
              <a href="https://www.linkedin.com/in/will-beckett-a7a660105/" className="underline hover:text-blue-600" target="_blank" rel="noopener noreferrer">Will</a>          
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default BaseLayout;
