import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';

function Loading() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
  
    const handleCancel = () => {
      const locations = [];
      for (let i = 1; i <= 5; i++) {
        const locationString = searchParams.get(`location${i}`);
        if (locationString) {
          const [name, lat, lon] = locationString.split('|');
          locations.push({ name, lat, lon });
        }
      }
      const preference = searchParams.get('preference');
      const placeType = searchParams.get('placeType');
  
      navigate('/', { state: { locations, preference, placeType } });
    };
  
    return <LoadingScreen onCancel={handleCancel} />;
  }


  export default Loading;

