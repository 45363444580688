import { getCachedJourneyTime, findNearestStation } from './Helper';
import { getDistanceFromLatLonInKm } from './Helper';

const heuristic = (point, locations) => {
  return locations.reduce((sum, loc) => sum + getDistanceFromLatLonInKm(point.lat, point.lon, loc.lat, loc.lon), 0);
};

const getNeighbors = (point, boundingBox, gridSize) => {
  const neighbors = [];
  const latOffsets = [-gridSize / 111000, 0, gridSize / 111000];
  const lonOffsets = [-gridSize / (111000 * Math.cos(point.lat * Math.PI / 180)), 0, gridSize / (111000 * Math.cos(point.lat * Math.PI / 180))];

  latOffsets.forEach(dLat => {
    lonOffsets.forEach(dLon => {
      if (dLat !== 0 || dLon !== 0) {
        const neighborLat = point.lat + dLat;
        const neighborLon = point.lon + dLon;

        if (neighborLat >= boundingBox.minLat && neighborLat <= boundingBox.maxLat && neighborLon >= boundingBox.minLon && neighborLon <= boundingBox.maxLon) {
          neighbors.push({ lat: neighborLat, lon: neighborLon });
        }
      }
    });
  });

  return neighbors;
};

const aStarSearch = async (locations, signal) => {
  const boundingBox = createBoundingBox(locations);
  const start = calculateGeographicMidpoint(locations);
  const target = start;

  const openSet = new Set();
  openSet.add(start);

  const cameFrom = new Map();

  const gScore = new Map();
  gScore.set(start, 0);

  const fScore = new Map();
  fScore.set(start, heuristic(start, locations));

  while (openSet.size > 0) {
    let current = null;
    let currentFScore = Infinity;

    openSet.forEach(point => {
      if (fScore.get(point) < currentFScore) {
        currentFScore = fScore.get(point);
        current = point;
      }
    });

    if (current.lat === target.lat && current.lon === target.lon) {
      return reconstructPath(cameFrom, current);
    }

    openSet.delete(current);

    const neighbors = getNeighbors(current, boundingBox, 2000); // Coarse grid
    for (const neighbor of neighbors) {
      const tentativeGScore = gScore.get(current) + await calculateTotalTravelTime(neighbor, locations, signal);

      if (tentativeGScore < (gScore.get(neighbor) || Infinity)) {
        cameFrom.set(neighbor, current);
        gScore.set(neighbor, tentativeGScore);
        fScore.set(neighbor, tentativeGScore + heuristic(neighbor, locations));

        if (!openSet.has(neighbor)) {
          openSet.add(neighbor);
        }
      }
    }
  }

  // Refine search with finer grid around best coarse grid point
  const bestCoarsePoint = reconstructPath(cameFrom, target);
  const fineNeighbors = getNeighbors(bestCoarsePoint, boundingBox, 500); // Fine grid
  let bestFinePoint = null;
  let lowestTime = Infinity;

  for (const neighbor of fineNeighbors) {
    const journeyTime = await calculateTotalTravelTime(neighbor, locations, signal);
    if (journeyTime < lowestTime) {
      lowestTime = journeyTime;
      bestFinePoint = neighbor;
    }
  }

  return bestFinePoint || start;
};

const calculateGeographicMidpoint = (locations) => {
  const sumLat = locations.reduce((sum, loc) => sum + loc.lat, 0);
  const sumLon = locations.reduce((sum, loc) => sum + loc.lon, 0);
  const midLat = sumLat / locations.length;
  const midLon = sumLon / locations.length;

  return { lat: midLat, lon: midLon };
};

const createBoundingBox = (points) => {
  const lats = points.map(p => p.lat);
  const lons = points.map(p => p.lon);
  return {
    minLat: Math.min(...lats),
    maxLat: Math.max(...lats),
    minLon: Math.min(...lons),
    maxLon: Math.max(...lons),
  };
};

const calculateTotalTravelTime = async (point, locations, signal) => {
  const travelTimes = await Promise.all(locations.map(location => 
    getCachedJourneyTime(location, point, location.accessibilityMode, signal)
  ));
  const totalTime = travelTimes.reduce((sum, time) => sum + time, 0);
  return totalTime;
};

const reconstructPath = (cameFrom, current) => {
  const totalPath = [current];
  while (cameFrom.has(current)) {
    current = cameFrom.get(current);
    totalPath.unshift(current);
  }
  return totalPath[0]; // The first element in the reconstructed path is the optimal point.
};

export default aStarSearch;
