import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { MapPinIcon, TrashIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';

const API_KEY = process.env.REACT_APP_TFL_API_KEY;

function LocationInput({ onAddLocation, onRemoveLocation, locations, maxLocations, onToggleAccessibility }) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchSuggestions = useCallback(debounce(async (input) => {
    if (input.length < 2) {
      setSuggestions([]);
      return;
    }
    try {
      const response = await fetch(`https://api.tfl.gov.uk/StopPoint/Search/${input}?modes=tube,dlr,overground&maxResults=5&app_key=${API_KEY}`);
      const data = await response.json();
      const stationSuggestions = data.matches
        .filter(match => match.modes.some(mode => ['tube', 'dlr', 'overground'].includes(mode)))
        .map(match => ({
          name: match.name,
          id: match.id,
          modes: match.modes,
          lat: match.lat,
          lon: match.lon
        }));
      setSuggestions(stationSuggestions);
    } catch (error) {
      console.log('===> ERROR: Error fetching suggestions:', error);
    }
  }, 300), []);

  useEffect(() => {
    fetchSuggestions(inputValue);
  }, [inputValue, fetchSuggestions]);

  const handleSuggestionClick = (suggestion) => {
    if (locations.length >= maxLocations) {
      setErrorMessage(`You can add a maximum of ${maxLocations} stations.`);
      return;
    }

    if (locations.some(loc => loc.name === suggestion.name)) {
      setErrorMessage("You've already added this station.");
    } else {
      onAddLocation({
        name: suggestion.name,
        lat: suggestion.lat,
        lon: suggestion.lon
      });
      setErrorMessage('');
    }
    setInputValue('');
    setSuggestions([]);
  };

  return (
    <div>
      <div className="flex mb-6">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Add TfL Station"
          className="flex-grow py-4 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm bg-lighter-orange placeholder-dark-blue" 
        />
      </div>
      {suggestions.length > 0 && (
        <ul className="mb-4 bg-white border border-gray-300 rounded-md shadow-lg">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              onClick={() => handleSuggestionClick(suggestion)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {suggestion.name} ({suggestion.modes.join(', ')})
            </li>
          ))}
        </ul>
      )}
      {errorMessage && (
        <p className="text-red-500 mb-2">{errorMessage}</p>
      )}
      <ul className="space-y-3">
        {locations.map((location, index) => (
          <li key={index} className="flex justify-between items-center py-2 px-4">
            <div className="flex items-center">
              <MapPinIcon className="h-5 w-5 mr-3 text-dark-blue" />
              <span className='text-dark-blue text-sm'>{location.name}</span>
            </div>
            <div className="flex space-x-2 items-center">
              <button
                onClick={() => onRemoveLocation(index)}
                className="text-dark-blue hover:text-dark-orange"
              >
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LocationInput;