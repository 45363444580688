import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useSearchParams } from 'react-router-dom';
import { findNearbyPlaces } from './Helper';
import { MapPinIcon, FunnelIcon } from '@heroicons/react/24/outline';

function Results() {
    const [searchParams] = useSearchParams();
    const [visiblePubs, setVisiblePubs] = useState(5);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const station = searchParams.get('station');
      const stationLat = searchParams.get('stationLat');
      const stationLon = searchParams.get('stationLon');
      const placeType = searchParams.get('placeType');
  
      if (station && stationLat && stationLon) {
        const fetchNearbyPlaces = async () => {
          try {
            const dummyRecordTiming = () => {};
            
            const nearbyPlaces = await findNearbyPlaces(
              parseFloat(stationLat), 
              parseFloat(stationLon), 
              placeType, 
              null, // Pass null for the signal as we're not using AbortController here
              dummyRecordTiming
            );
            
            setResult({
              station,
              stationLat,
              stationLon,
              places: nearbyPlaces
            });
          } catch (error) {
            console.error('Error fetching nearby places:', error);
            setError('Failed to fetch nearby places. Please try again.');
          }
        };
  
        fetchNearbyPlaces();
      } else {
        setError('Invalid URL parameters. Please start a new search.');
      }
    }, [searchParams]);
  
    const handleShowMore = () => {
      setVisiblePubs(prevVisiblePubs => prevVisiblePubs + 4);
      ReactGA.event({
        category: 'engagement',
        action: 'show_more',
        label: 'Show More button clicked',
        value: 1
      });
    };
  
    if (error) {
      return <div className="text-red-500">{error}</div>;
    }
  
    if (!result) {
      return <div>Loading results...</div>;
    }
  
    return (
      <ResultDisplay result={result} onShowMore={handleShowMore} visiblePubs={visiblePubs} />
    );
}

function ResultDisplay({ result, onShowMore, visiblePubs }) {
  const closestPlace = result.places && result.places.length > 0 ? result.places[0] : null;
  
  const handleShare = async () => {
    const shareData = {
      title: 'Meet here!',
      text: `Let's meet at ${result.station}${closestPlace ? ` near ${closestPlace.name}` : ''}!`,
      url: window.location.href
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        navigator.clipboard.writeText(window.location.href)
          .then(() => {
            alert('URL has been copied to clipboard!');
          })
          .catch(err => {
            console.error('Failed to copy: ', err);
          });
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const handleSearchByName = ({ result }) => {
    const placeName = result.station;
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(placeName)}`;
    window.open(url, '_blank');
  };

  return (
    <div>
      <h2 className="text-xl mb-4 text-dark-blue">Meet here! ←</h2>
      <div className="flex items-center mb-4">
        <MapPinIcon className="h-5 w-5 text-[#F07167] mr-2" />
        <p className="text-dark-blue font-bold">{result.station}</p>
      </div>
      <div className="bg-dark-orange rounded-xl p-6 mb-6">
        <h3 className="font-semibold text-white mb-1">{closestPlace ? closestPlace.name : 'No place found'}</h3>
        <p className="text-xs text-white mb-4 pb-8">{closestPlace ? closestPlace.address : 'Address not available'}</p>
        <div className="flex space-x-2">
          <button
            className="flex-1 bg-light-orange text-pale-yellow py-2 px-4 rounded-full text-sm"
            onClick={() => handleSearchByName({ result })}
          >
            Open in Maps
          </button>
          <button 
            className="flex-1 bg-light-orange text-pale-yellow py-2 px-4 rounded-full text-sm"
            onClick={handleShare}
          >
            Share
          </button>
        </div>
      </div>
      <h3 className="text-lg font-semibold mb-4 text-dark-blue">
         Nearby <FunnelIcon className="h-5 w-5 inline" /> 
      </h3>
      <div className="grid grid-cols-2 gap-4">
        {result.places && result.places.slice(1, visiblePubs).map((place, index) => (
          <div key={`${place.id}-${index}`} className="bg-lighter-orange rounded-xl p-3 mb-1">
            <p className="font-semibold text-sm text-dark-green">{place.name}</p>
            <p className="text-xs text-dark-green pb-8">
              {place.distance < 1 
                ? `${(place.distance * 1000).toFixed(0)}m away` 
                : `${place.distance.toFixed(1)}km away`}
            </p>
          </div>
        ))}
      </div>
      {result.places && result.places.length > visiblePubs && (
        <button 
          onClick={onShowMore}
          className="w-full bg-dark-orange font-bold text-pale-yellow py-3 px-4 rounded-full text-sm mt-4"
        >
          Show more options
        </button>
      )}
    </div>
  );
}

export default Results;
