import ReactGA from 'react-ga';
import aStarSearch from './aStarSearch';

const API_KEY = process.env.REACT_APP_TFL_API_KEY;

const journeyTimeCache = new Map();

export const getCachedJourneyTime = async (from, to, accessibilityMode, signal) => {
    const key = `${from.lat},${from.lon}-${to.lat},${to.lon}-${accessibilityMode}`;
    if (journeyTimeCache.has(key)) {
        console.log(`===> Cache HIT for: ${key}`);
      return journeyTimeCache.get(key);
    }
    const time = await getJourneyTime(from, to, accessibilityMode, signal);
    journeyTimeCache.set(key, time);
    return time;
};

export const calculateMaxDistance = (locations) => {
  let maxDistance = 0;
  for (let i = 0; i < locations.length; i++) {
    for (let j = i + 1; j < locations.length; j++) {
      const distance = getDistanceFromLatLonInKm(locations[i].lat, locations[i].lon, locations[j].lat, locations[j].lon);
      if (distance > maxDistance) {
        maxDistance = distance;
      }
    }
  }
  console.log(`===> Max distance between stops: ${maxDistance}`);
  return maxDistance;
};


const distanceThreshold = 3;
export const calculateOptimalMeetingPoint = async (locations, preference, signal, recordTiming) => {
    const startTime = performance.now();
    let optimalPoint;
  
    if (preference === 'location') {
      optimalPoint = calculateGeographicMidpoint(locations);
    } else {
      const maxDistance = calculateMaxDistance(locations);
      if (maxDistance > distanceThreshold) {
        console.log('Using A* algorithm for finding optimal meeting point.');
        optimalPoint = await aStarSearch(locations, signal);
      } else {
        console.log('Using geographic midpoint for finding optimal meeting point.');
        optimalPoint = calculateGeographicMidpoint(locations);
      }
    }
  
    if (!optimalPoint) {
      console.log('A* search failed to find optimal point, falling back to geographic midpoint');
      ReactGA.event({
        category: 'result',
        action: 'fallback_to_geographic_midpoint',
        label: 'A* search failed',
      });
      optimalPoint = calculateGeographicMidpoint(locations);
    }
  
    const nearestStation = await findNearestStation(optimalPoint, signal);
    const endTime = performance.now();
  
    recordTiming('API', 'calculate_optimal_point', endTime - startTime, 'Calculate optimal meeting point');
  
    return { ...nearestStation, isGeographicMidpoint: preference === 'location' };
};

const calculateGeographicMidpoint = (locations) => {
    const sumLat = locations.reduce((sum, loc) => sum + loc.lat, 0);
    const sumLon = locations.reduce((sum, loc) => sum + loc.lon, 0);
    const midLat = sumLat / locations.length;
    const midLon = sumLon / locations.length;
  
    return { lat: midLat, lon: midLon };
};
  
const findNearestStation = async (point, signal) => {
    console.log(`===> Finding nearest station for: ${point.name}`);
    console.log("CALLING THE API TO FIND NEAREST STATION")
    const url = `https://api.tfl.gov.uk/StopPoint?lat=${point.lat}&lon=${point.lon}&stopTypes=NaptanMetroStation,NaptanRailStation&radius=1000&app_key=${API_KEY}`;
  
    try {
      const response = await fetch(url, { signal });
      const data = await response.json();
      if (data.stopPoints && data.stopPoints.length > 0) {
        console.log(`===> Nearest station found for: ${point.name}`);
        return {
          name: data.stopPoints[0].commonName,
          lat: data.stopPoints[0].lat,
          lon: data.stopPoints[0].lon
        };
      }
      console.log(`===> No nearest station found for: ${point.name}`);
      return { name: "Nearest Point", lat: point.lat, lon: point.lon };
    } catch (error) {
      console.error('Error finding nearest station:', error);
      return { name: "Nearest Point", lat: point.lat, lon: point.lon };
    }
};

const getJourneyTime = async (from, to, accessibilityMode, signal) => {
    const startTime = performance.now();
    console.log("CALLING THE API TO GET JOURNEY TIME")
    let url = `https://api.tfl.gov.uk/Journey/JourneyResults/${from.lat},${from.lon}/to/${to.lat},${to.lon}?app_key=${API_KEY}`;
    
    if (accessibilityMode) {
      console.log(`===> Accessibility mode is true for: ${from.name} to ${to.name}`);
      url += '&accessibilityPreference=stepFreeToVehicle,stepFreeToPlatform';
    }
  
    try {
      const response = await fetch(url, { signal });
      const data = await response.json();
      const endTime = performance.now();
      
      ReactGA.timing({
        category: 'API',
        variable: 'get_journey_time',
        value: endTime - startTime,
        label: 'Get journey time from TfL API',
      });
  
      if (data.journeys && data.journeys.length > 0) {
        return data.journeys[0].duration;
      }
      console.log(`===> No journey time found for: ${from.name} to ${to.name}`);
      return Infinity;
    } catch (error) {
      console.error('Error fetching journey time:', error);
      return Infinity;
    }
};

export const findNearbyPlaces = async (lat, lon, placeType, signal, recordTiming) => {
    console.log(`findNearbyPlaces started with lat: ${lat}, lon: ${lon}, type: ${placeType}`);
    const startTime = performance.now();
    const places = await fetchPlaces(lat, lon, placeType, signal);
    const endTime = performance.now();
    
    recordTiming('API', 'fetch_nearby_places', endTime - startTime, 'Fetch nearby places');
  
    if (places.length === 0) {
      console.log('===> ERROR: No places found');
    } else {
      console.log('Nearby places:', places.slice(0, 5));
    }
    return places;
};

const fetchPlaces = async (lat, lon, placeType, signal) => {
    try {
      const response = await fetch('fhrs_data_2024-08-05.csv', { signal });
      const csvData = await response.text();
      
      let places = [];
      const lines = csvData.split('\n');
      const headers = lines[0].split(',');
      
      for (let i = 1; i < lines.length; i++) {
        const values = lines[i].split(',');
        const type = values[headers.indexOf('function')];
        
        if ((placeType === 'pub' && type === 'Pub/bar/nightclub') ||
            (placeType === 'restaurant' && type === 'Restaurant/Cafe/Canteen') ||
            placeType === 'both') {
          const place = {
            id: values[headers.indexOf('LocalAuthorityCode')],
            name: values[headers.indexOf('business_name')],
            address: values[headers.indexOf('address')],
            type: type,
            rating: parseInt(values[headers.indexOf('rating')]),
            latitude: parseFloat(values[headers.indexOf('latitude')]),
            longitude: parseFloat(values[headers.indexOf('longitude')]),
          };
          
          if (place.latitude && place.longitude) {
            place.distance = getDistanceFromLatLonInKm(lat, lon, place.latitude, place.longitude);
            places.push(place);
          }
        }
      }
  
      // Sort places by distance
      places.sort((a, b) => a.distance - b.distance);
  
      return places;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
        return [];
      }
      console.log('===> ERROR: Error fetching places:', error);
      return [];
    }
};

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180);
}
