import React, { useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import landingSvg from '../images/landing.svg';
import { calculateOptimalMeetingPoint } from './Helper';
import LocationInput from './LocationInput';

function Home() {
    const [locations, setLocations] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [calculationPreference, setCalculationPreference] = useState('location');
    const [placeType, setPlaceType] = useState('both');
    const navigate = useNavigate();
    const controllerRef = useRef(null);
  
    const handleAddLocation = (location) => {
      ReactGA.event({
        category: 'user_interaction',
        action: 'add_location',
        label: location.name,
      });
      if (locations.length < 5) {
        setLocations([...locations, { ...location, accessibilityMode: false }]);
      }
    };
  
    const handleRemoveLocation = (index) => {
      ReactGA.event({
        category: 'user_interaction',
        action: 'remove_location',
        label: locations[index].name,
      });
      setLocations(locations.filter((_, i) => i !== index));
    };
  
    const handleFindPlace = async () => {
      ReactGA.event({
        category: 'engagement',
        action: 'find_place',
        label: `Find ${placeType} button clicked`,
        value: 1
      });
  
      if (locations.length < 2) {
        alert("Please add at least two locations.");
        return;
      }
  
      const searchParams = new URLSearchParams();
      locations.forEach((loc, index) => {
        searchParams.append(`location${index + 1}`, `${loc.name}|${loc.lat}|${loc.lon}`);
      });
      searchParams.append('preference', calculationPreference);
      searchParams.append('placeType', placeType);
  
      navigate(`/loading?${searchParams.toString()}`);
  
      try {
        controllerRef.current = new AbortController();
        const optimalPoint = await calculateOptimalMeetingPoint(
          locations, 
          calculationPreference,
          controllerRef.current.signal,
          (category, variable, value, label) => {
            ReactGA.timing({ category, variable, value, label });
          }
        );
  
        searchParams.append('station', optimalPoint.name);
        searchParams.append('stationLat', optimalPoint.lat);
        searchParams.append('stationLon', optimalPoint.lon);
  
        navigate(`/results?${searchParams.toString()}`);
  
      } catch (error) {
        console.error('Error in handleFindPlace:', error);
        setErrorMessage(`Error finding ${placeType}: ${error.message}`);
        ReactGA.event({
          category: 'result',
          action: 'find_place_error',
          label: error.message,
        });
        navigate('/');
      }
    };
  
    return (
      <div className="max-w-md mx-auto">
        <img src={landingSvg} alt="Landing illustration" className="w-40 h-40 mx-auto mb-6" />
        <h2 className="text-xl mb-4 text-center font-medium text-dark-blue">Find a location</h2>
        <p className="text-sm text-center mb-6 text-dark-blue">Enter 2-5 starting locations to find the perfect central meeting point in London.</p>
        
        <LocationInput 
          onAddLocation={handleAddLocation} 
          onRemoveLocation={handleRemoveLocation}
          locations={locations}
          maxLocations={5}
        />
            
        <div className="w-full mb-4" style={{ height: '1px', backgroundColor: '#D9D9D9' }} />
        <div className="mb-4 text-dark-blue">
          <label className="block text-sm font-medium mb-2">Give me results by</label>
          <div className="flex space-x-2">
            <button
              onClick={() => setCalculationPreference('location')}
              className={`py-2 px-4 text-sm rounded-full transition-colors duration-200 flex items-center justify-center space-x-2 ${
                calculationPreference === 'location'
                  ? 'bg-white border border-dark-blue'
                  : 'bg-white border border-dark-blue hover:bg-gray-100'
              }`}
            >
              <span className={`w-4 h-4 rounded-full border ${
                calculationPreference === 'location'
                  ? 'bg-[#F07167] border-[#F07167]'
                  : 'border-gray-400'
              }`}></span>
              <span>Location</span>
            </button>
            <button
              onClick={() => setCalculationPreference('time')}
              className={`py-2 px-4 text-sm rounded-full transition-colors duration-200 flex items-center justify-center space-x-2 ${
                calculationPreference === 'time'
                  ? 'bg-white border border-dark-blue'
                  : 'bg-white border border-dark-blue hover:bg-gray-100'
              }`}
            >
              <span className={`w-4 h-4 rounded-full border ${
                calculationPreference === 'time'
                  ? 'bg-[#F07167] border-[#F07167]'
                  : 'border-gray-400'
              }`}></span>
              <span>Travel time</span>
            </button>
          </div>
        </div>
  
        <div className="w-full mb-4" style={{ height: '1px', backgroundColor: '#D9D9D9' }} />
        <div className="mb-6 text-dark-blue">
          <label className="block text-sm font-medium mb-2">Show me</label>
          <div className="relative">
            <select
              value={placeType}
              onChange={(e) => setPlaceType(e.target.value)}
              className="block w-full pl-3 pr-10 py-2 text-base border border-dark-blue focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-full appearance-none"
            >
              <option value="both">Restaurants + Pubs</option>
              <option value="restaurant">Restaurants</option>
              <option value="pub">Pubs</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
  
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        
        <button
          onClick={handleFindPlace}
          className={`w-full bg-dark-orange text-pale-yellow py-3 px-4 rounded-full text-sm font-medium ${locations.length < 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={locations.length < 2}
        >
          Find a location
        </button>
      </div>
    );
  }

export default Home;

