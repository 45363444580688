import React from 'react';
import landingSvg from '../images/landing.svg';

function LoadingScreen({ onCancel }) {
  return (
    <div className="text-center text-dark-blue">
      <img src={landingSvg} alt="Loading illustration" className="w-64 h-64 mx-auto mb-8" />
      <h2 className="text-2xl mb-4">Finding the best location</h2>
      <p className="mb-4">Loading...</p>
      <button 
        onClick={onCancel}
        className="w-full bg-dark-orange text-pale-yellow py-3 px-4 rounded-full hover:bg-gray-200 transition duration-300"
      >
        Cancel
      </button>
    </div>
  );
}

export default LoadingScreen;