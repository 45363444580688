import React from 'react';
import ReactGA from 'react-ga';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import BaseLayout from './components/BaseLayout';
import Home from './components/Home';
import Loading from './components/Loading';
import Results from './components/Results';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Home />} />
          <Route path="results" element={<Results />} />
          <Route path="loading" element={<Loading />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;